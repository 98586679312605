import React from 'react';

// icons
import { ReactComponent as USDT } from '../assets/images/currency/USDT.svg';
import { ReactComponent as USDC } from '../assets/images/currency/USDC.svg';
import { ReactComponent as DAI } from '../assets/images/currency/DAI.svg';

const MixedToken = () => {
  return (
    <div className="flex items-center mixed-currency">
      <USDT />
      <USDC />
      <DAI />
    </div>
  )
};

export default MixedToken;
