import {
  createContext,
} from 'react';

export interface DepositContextData {
  currentStep: number;
  changeCurrentStep: (step: number) => void;
  selectedCurrency: StableType | null;
  changeSelectedCurrency: (currency: StableType) => void;
  optimized: boolean;
  changeOptimized: (optimized: boolean) => void;
  depositAmount: string;
  changeDepositAmount: (amount: string) => void;
  optimizedGasPrice: number;
  changeOptimizedGasPrice: (gasPrice: number) => void;
  gasPrice: number;
  changeGasPrice: (gasPrice: number) => void;
  resetContext: () => void;
  interestRate: number;
  setInterestRate: (interestRate: number) => void;
  resetCurrentStepToStep: (stepIndex: number) => void;
}

export const DepositContext = createContext<DepositContextData | undefined>(
  undefined
);