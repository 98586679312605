import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from 'react-router-dom';
import './index.css';
import { Web3Modal } from '@web3modal/react';
import {
  configureChains,
  createConfig,
  mainnet,
  WagmiConfig,
  useAccount
} from 'wagmi';
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from '@web3modal/ethereum';
import * as buffer from 'buffer';
import './i18n';

// components
import Layout from './routes/Layout';
import ErrorPage from './routes/ErrorPage';
import DepositPage from './routes/DepositPage';
import WithdrawalPage from './routes/WithdrawalPage';
import DashboardPage from './routes/DashboardPage';
import TransactionsStatisticsPage from './routes/TransactionsStatisticsPage';

window.Buffer = buffer.Buffer;

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Navigate to="/deposit" replace />,
      },
      {
        path: 'deposit/:customPart?',
        element: <DepositPage />,
      },
      {
        path: 'withdraw',
        element: <WithdrawalPage />,
      },
      {
        path: 'dashboard',
        element: <DashboardPage />,
      },
      {
        path: 'transactions',
        element: <TransactionsStatisticsPage />,
      },
      {
        path: '/*',
        element: <Navigate to="/deposit" replace />,
      }
    ]
  },
]);

const chains = [mainnet];
const projectId = process.env.REACT_APP_WEB3_MODAL_PROJECT_ID || '';
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    ...w3mConnectors({ projectId, chains }),
    // new CoinbaseWalletConnector({
    //   chains,
    //   options: {
    //     appName: 'DSF',
    //   },
    // }),
    // new MetaMaskConnector({ chains }),
  ],
  publicClient
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

const APP_VERSION = '2.2.8'; // Обновите версию при каждом изменении

const currentVersion = localStorage.getItem('app_version');
if (currentVersion !== APP_VERSION) {
  if ('caches' in window) {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }
  localStorage.setItem('app_version', APP_VERSION);
}


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <RouterProvider router={router} />
    </WagmiConfig>

    <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
  </React.StrictMode>
);
