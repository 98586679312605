import {
  useEffect,
  useState,
} from 'react';
import {
  useAccount,
  useBalance,
} from 'wagmi';
import tokensAddresses from '../utils/tokens_addresses.json';

const useTokensBalance = () => {
  const [usdtBalance, setUSDTBalance] = useState<number>(0);
  const [usdcBalance, setUSDCBalance] = useState<number>(0);
  const [daiBalance, setDAIBalance] = useState<number>(0);
  const { address} = useAccount();

  const usdtBalanceResponse = useBalance({
    address,
    token: tokensAddresses.USDT as `0x${string}`,
    watch: true,
  });
  const usdcBalanceResponse = useBalance({
    address,
    token: tokensAddresses.USDC as `0x${string}`,
    watch: true,
  });
  const daiBalanceResponse = useBalance({
    address,
    token: tokensAddresses.DAI as `0x${string}`,
    watch: true,
  });

  useEffect(() => {
    if (
      !usdtBalanceResponse.isFetching &&
      usdtBalanceResponse.isSuccess &&
      usdtBalanceResponse.data &&
      !isNaN(+usdtBalanceResponse.data.formatted)
    ) {
      setUSDTBalance(+usdtBalanceResponse.data.formatted);
    }
  }, [usdtBalanceResponse]);

  useEffect(() => {
    if (
      !usdcBalanceResponse.isFetching &&
      usdcBalanceResponse.isSuccess &&
      usdcBalanceResponse.data &&
      !isNaN(+usdcBalanceResponse.data.formatted)
    ) {
      setUSDCBalance(+usdcBalanceResponse.data.formatted);
    }
  }, [usdcBalanceResponse]);

  useEffect(() => {
    if (
      !daiBalanceResponse.isFetching &&
      daiBalanceResponse.isSuccess &&
      daiBalanceResponse.data &&
      !isNaN(+daiBalanceResponse.data.formatted)
    ) {
      setDAIBalance(+daiBalanceResponse.data.formatted);
    }
  }, [daiBalanceResponse]);

  return {
    USDT: usdtBalance,
    USDC: usdcBalance,
    DAI: daiBalance,
  }
};

export default useTokensBalance;
