import {
  useCallback,
  useState,
  useEffect,
  useRef, useMemo,
} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ethers } from 'ethers';
import { useAccount } from 'wagmi';
import {Trans, useTranslation} from 'react-i18next';


import { ReactComponent as ArrowLeftIcon } from '../assets/images/common/arrow_left.svg';

// styles
import './styles/transactions.css';

// components
import Card from '../components/Card';
import Transaction from './transactions/Transaction';
import DesktopTransaction from './transactions/DesktopTransaction';

const TransactionsStatisticsPage = () => {
  const [transactions, setTransactions] = useState<Array<Transaction>>([]);
  const [timestamps, setTimestamps] = useState<Array<number>>([]);
  const provider = useRef(ethers.getDefaultProvider('https://rpc.ankr.com/eth'));
  const { address } = useAccount();
  const { t }  = useTranslation('statistics');

  const sortedTransactions = useMemo(() => {
    const pendingTransactions = transactions.filter((transaction) => transaction.state === 'pending');
    const pendingDeposits = pendingTransactions.filter((transaction) => transaction.action === 'deposit');
    const pendingWithdrawals = pendingTransactions.filter((transaction) => transaction.action === 'withdrawal');
    let pendingDeposit: Transaction | undefined;
    let pendingWithdrawal: Transaction | undefined;
    if (pendingDeposits.length) {
      pendingDeposit = pendingDeposits.reduce((acc, transaction) => {
        acc.amount += transaction.amount;
        acc.lpshares += acc.lpshares;
        acc.gasSaved += transaction.gasSaved;
        acc.ETHPrice = transaction.ETHPrice;
        acc.timestamp = transaction.timestamp;
        acc.blockNumber = transaction.blockNumber;
        acc.txHash = transaction.txHash;
        acc.lpPrice = transaction.lpPrice;
        // TODO: also filter by currency later on
        return acc;
      });
    }
    if (pendingWithdrawals.length) {
      pendingWithdrawal = pendingWithdrawals.reduce((acc, transaction) => {
        acc.amount += transaction.amount;
        acc.lpshares += acc.lpshares;
        acc.gasSaved += transaction.gasSaved;
        acc.ETHPrice = transaction.ETHPrice;
        acc.timestamp = transaction.timestamp;
        acc.blockNumber = transaction.blockNumber;
        acc.txHash = transaction.txHash;
        acc.lpPrice = transaction.lpPrice;
        return acc;
      });
    }

    const completedTransactions = transactions.filter((transaction) => transaction.state === 'fulfilled');
    if (pendingDeposit) {
      completedTransactions.push(pendingDeposit);
    }
    if (pendingWithdrawal) {
      completedTransactions.push(pendingWithdrawal);
    }

    return completedTransactions.sort((a, b) => +b.blockNumber - +a.blockNumber);
  }, [transactions]);

  // const getTransactions = useCallback(async () => {
  //   const response = await axios.get(`https://api.dsf.finance/deposit/all/${address}`);
  //   return response.data;
  // }, [address]);

  const getTransactions = useCallback(async () => {
    const urls = [
      `https://api.dsf.finance/deposit/all/${address}`,
      `https://api2.dsf.finance/api/deposit/all/${address}`
    ];
  
    try {
      const response = await Promise.race(urls.map(url => axios.get(url)));
      return response.data;
    } catch (error) {
      console.error("Error fetching transactions:", error);

      throw error;
    }
  }, [address]);

  const getTimestamp = useCallback(async (blockNumber: number) => {
    const blockInfo = await provider.current.getBlock(blockNumber);

    return (blockInfo?.timestamp || 0) * 1000;
  }, []);

  useEffect(() => {
    getTransactions().then((res) => {
      const deposits: Transaction[] = res.deposits.map((deposit: Deposit) => ({
        ...deposit,
        action: 'deposit',
      }));
      const withdrawals = res.withdrawals.map((withdrawal: Withdrawal) => ({
        ...withdrawal,
        action: 'withdrawal',
      }));
      setTransactions(deposits.concat(withdrawals));
    });
  }, [getTransactions]);

  useEffect(() => {
    if (sortedTransactions) {
      const getTimestampsPromises = sortedTransactions.map((transaction: Transaction) => getTimestamp(+transaction.blockNumber));
      Promise.all(getTimestampsPromises).then((res) => {
        setTimestamps(res);
      });
    }
  }, [getTimestamp, sortedTransactions]);

  return (
    <>
      <div className="flex flex-col tablet:flex-row tablet:justify-between tablet:items-center">
        <div>
          <h2 className="text-gray-900 font-bold text-[24px] leading-8">
            {t('transactions_statistics')}
          </h2>
          <Link to="/dashboard" className="flex items-center gap-2 mt-5 text-gray-900 cursor-pointer">
            <ArrowLeftIcon/>
            <Trans t={t} ns="deposit">
              back_<span className="font-bold">dashboard</span>
            </Trans>
          </Link>
        </div>
        <div>
          {/*filters*/}
        </div>
      </div>
      <ul className="flex flex-col gap-3 text-[14px] leading-[20px] mt-4 tablet:hidden">
        {
          timestamps.length ? sortedTransactions.map((transaction, index) => (
            <li key={transaction.id}>
              <Transaction
                timestamp={new Date(timestamps[index])}
                transactionType={transaction.transactionType}
                transactionAction={transaction.action}
                transactionToken={transaction.currency}
                transactionAmount={+transaction.amount}
                amountPlaced={+transaction.lpshares * +transaction.lpPrice}
                commission={+transaction.gasSpent}
                isFinished={transaction.state === 'fulfilled'}
                txHash={transaction.txHash}
                ETHPrice={+transaction.ETHPrice}
              />
            </li>
          )) : null
        }
      </ul>
      <div className="hidden tablet:block mt-4">
        <Card>
          <table className="text-[14px] leading-[20px] w-full border-separate border-spacing-y-2">
            <thead>
            <tr>
              <th className="text-left font-normal">{t('date')}</th>
              <th className="text-left font-normal">{t('operation_type')}</th>
              <th className="text-left font-normal">{t('coin')}</th>
              <th className="text-left font-normal">{t('Commissions_spent')}</th>
              <th className="text-left font-normal">{t('status')}</th>
              <th />
              <th />
            </tr>
            </thead>
            <tbody className="text-gray-900">
            {
              timestamps.length ? sortedTransactions.map((transaction, index) => (
                <DesktopTransaction
                  key={transaction.id}
                  timestamp={new Date(timestamps[index])}
                  transactionType={transaction.transactionType}
                  transactionAction={transaction.action}
                  transactionToken={transaction.currency}
                  transactionAmount={+transaction.amount}
                  amountPlaced={+transaction.lpshares * +transaction.lpPrice}
                  commission={+transaction.gasSpent}
                  isFinished={transaction.state === 'fulfilled'}
                  txHash={transaction.txHash}
                  ETHPrice={+transaction.ETHPrice}
                />
              )) : null
            }
            </tbody>
          </table>
        </Card>
      </div>
    </>
  );
};

export default TransactionsStatisticsPage;
