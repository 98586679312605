import {
  useState,
  useCallback,
} from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// icons
import { ReactComponent as FlagEN } from '../assets/images/flags/en.svg';

// components
// import Toggle from './Toggle';
import Dropdown from './CustomDropdown';

// styles
import './styles/navigation.css';

const Navigation = () => {
  const [openedBurger, setOpenedBurger] = useState(false);
  // const [activeProMode, setActiveProMode] = useState(false);
  const { t } = useTranslation(['header', 'common']);

  // const toggleProMode = useCallback(() => {
  //   setActiveProMode(!activeProMode);
  // }, [activeProMode]);

  const toggleBurger = useCallback(() => {
    setOpenedBurger(!openedBurger);
  }, [openedBurger]);

  const closeBurgerMenu = useCallback(() => {
    setOpenedBurger(false);
  }, [setOpenedBurger]);

  return (
    <div>
      <div className="tablet:hidden">
        <label className="burger" htmlFor="burger">
          <input type="checkbox" id="burger" checked={openedBurger} onChange={toggleBurger} />
          <span className="bg-blue"></span>
          <span className="bg-blue"></span>
          <span className="bg-blue"></span>
        </label>
      </div>
      <div hidden={!openedBurger} className="fixed top-0 left-0 z-[10] w-[220px] h-screen text-gray-900 bg-[linear-gradient(#FBFBFD_50%,_#E9F6FE_87%,_#E0F3FF)] animate-open-menu tablet:inline-block tablet:static tablet:w-[unset] tablet:h-[unset] tablet:bg-[linear-gradient(transparent,_transparent)]">
        <div className="flex flex-col justify-center items-center w-full h-full tablet:flex-row">
          <nav className="flex flex-col gap-14 w-[132px] tablet:w-[initial] tablet:flex-row">
            <ul className="flex flex-col gap-5 tablet:flex-row tablet:gap-6">
              <NavLink
                to="/dashboard"
                onClick={closeBurgerMenu}
                className={({ isActive }) => isActive ? 'font-bold text-blue-100' : ''}
              >
                {t('dashboard')}
              </NavLink>
              <hr className="tablet:rotate-90 tablet:w-4 m-auto w-full" />
              <NavLink
                to="/deposit"
                onClick={closeBurgerMenu}
                className={({ isActive }) => isActive ? 'font-bold text-blue-100' : ''}
              >
                {t('deposit', { ns: 'common' })}/{t('withdraw', { ns: 'common' })}
              </NavLink>
            </ul>
            {/*<p className="flex items-center justify-between">*/}
            {/*  <span className="tablet:mr-6">Pro Mode</span>*/}
            {/*  <Toggle checked={activeProMode} onChange={toggleProMode} />*/}
            {/*</p>*/}
            {/*<Dropdown*/}
            {/*  label={*/}
            {/*    <div className="flex items-center min-w-[112px] tablet:min-w-[initial]">*/}
            {/*      <FlagEN className="mr-4" />*/}
            {/*      EN*/}
            {/*    </div>*/}
            {/*  } items={[*/}
            {/*    (*/}
            {/*      <div className="flex items-center px-2">*/}
            {/*        <FlagEN className="mr-4" />*/}
            {/*        EN*/}
            {/*      </div>*/}
            {/*    ),*/}
            {/*  ]}*/}
            {/*  itemsValues={['EN']}*/}
            {/*  onChange={(value) => null}*/}
            {/*/>*/}
          </nav>
        </div>
      </div>
    </div>
  )
};

export default Navigation;
