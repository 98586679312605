import {
  createContext,
} from 'react';

export interface GlobalContextData {
  wallet: string;
  changeWallet: (wallet: string) => void;
  balance: BalanceType;
  changeBalance: (currency: StableType, amount: number) => void;
  ETHPrice: number;
  setETHPrice: (ETHPrice: number) => void;
  managedInDSF: number;
  changeManagedInDSF: (managedInDSFUSD: number) => void;
  changeDsfLpBalance: (lpBalance: bigint) => void;
  gasPrice: number;
  changeGasPrice: (gasPrice: number) => void;
  dsfLPPrice: number;
  changeDSFLPPrice: (dsfLPPrice: number) => void;
  dsfLpTotalSupply: number;
  changeDSFLPTotalSupply: (dsfLPTotalSupply: number) => void;
  userDeposits: UserDeposits;
  changeUserDeposits: (userDeposits: UserDeposits) => void;
  interestRate: number;
  changeInterestRate: (interestRate: number) => void;
  currentInterestRate: number;
  changeCurrentInterestRate: (currentInterestRate: number) => void;
  withdrawableIncome: number;
  changeWithdrawableIncome: (withdrawableIncome: number) => void;
  dsfLpBalance: bigint;
}

export const GlobalContext = createContext<GlobalContextData | undefined>(
  undefined
);