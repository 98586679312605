import { createContext } from 'react';

export interface WithdrawalContextData {
  currentStep: number;
  selectedCurrency: StableType | null;
  amountToWithdraw: string;
  optimized: boolean;
  changeCurrentStep: (step: number) => void;
  changeSelectedCurrency: (currency: StableType) => void;
  changeAmountToWithdraw: (amount: string) => void;
  changeOptimized: (optimized: boolean) => void;
  optimizedGasPrice: number;
  changeOptimizedGasPrice: (gasPrice: number) => void;
  gasPrice: number;
  changeGasPrice: (gasPrice: number) => void;
  resetContext: () => void;
  resetCurrentStepToStep: (stepIndex: number) => void;
  amountToWithdrawInPercentage: number;
  changeAmountToWithdrawInPercentage: (percentage: number) => void;
}

export const WithdrawalContext = createContext<WithdrawalContextData | undefined>(undefined);