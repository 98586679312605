import {
  useState,
  useMemo,
  useCallback,
} from 'react';
import { WithdrawalContextData } from '../context/WithdrawalContext';

const useWithdrawalContextValue = (): WithdrawalContextData => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [selectedCurrency, setSelectedCurrency] = useState<StableType | null>(null);
  const [amountToWithdraw, setAmountToWithdraw] = useState<string>('');
  const [optimized, setOptimized] = useState<boolean>(true);
  const [optimizedGasPrice, setOptimizedGasPrice] = useState<number>(0);
  const [gasPrice, setGasPrice] = useState<number>(0);
  const [amountToWithdrawInPercentage, setAmountToWithdrawInPercentage] = useState<number>(0);

  const resetContext = useCallback(() => {
    setCurrentStep(0);
    setSelectedCurrency(null);
    setOptimized(true);
    setAmountToWithdraw('');
  },[
    setCurrentStep, setSelectedCurrency,
    setOptimized, setAmountToWithdraw,
  ]);

  const resetCurrentStepToStep = useCallback((stepIndex: number) => {
    if (stepIndex > currentStep) return;
    if (stepIndex === 0) {
      setCurrentStep(0);
      setOptimized(true);
      return;
    }
    setCurrentStep(stepIndex);
  }, [setCurrentStep, currentStep, setOptimized]);

  return useMemo(() => ({
    currentStep,
    changeCurrentStep: setCurrentStep,
    selectedCurrency,
    changeSelectedCurrency: setSelectedCurrency,
    amountToWithdraw,
    changeAmountToWithdraw: setAmountToWithdraw,
    optimized,
    changeOptimized: setOptimized,
    optimizedGasPrice,
    changeOptimizedGasPrice: setOptimizedGasPrice,
    gasPrice,
    changeGasPrice: setGasPrice,
    resetContext,
    resetCurrentStepToStep,
    amountToWithdrawInPercentage,
    changeAmountToWithdrawInPercentage: setAmountToWithdrawInPercentage,
  }), [
    currentStep, setCurrentStep,
    selectedCurrency, setSelectedCurrency,
    amountToWithdraw, setAmountToWithdraw,
    optimized, setOptimized,
    optimizedGasPrice, setOptimizedGasPrice,
    gasPrice, setGasPrice,
    resetContext, resetCurrentStepToStep,
    amountToWithdrawInPercentage, setAmountToWithdrawInPercentage,
  ]);
};

export default useWithdrawalContextValue;
