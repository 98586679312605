import {
  useCallback,
  useMemo,
} from 'react';
import {
  useContractRead,
  erc20ABI,
} from 'wagmi';
import { ethers } from 'ethers';
import useDepositContext from '../../../hooks/useDepositContext';
import useGlobalContext from '../../../hooks/useGlobalContext';
import useApprove from '../../../hooks/useApprove';
import { Tooltip } from 'flowbite-react';
import tokensAddresses from '../../../utils/tokens_addresses.json';
import dsfAddresses from '../../../utils/dsf_addresses.json';
import { useTranslation } from 'react-i18next';

import '../../styles/icons.css';

// icons
import { ReactComponent as QuestionIcon } from '../../../assets/images/common/question.svg';
import { ReactComponent as WalletIcon } from '../../../assets/images/common/wallet.svg';

// components
import Button from '../../../components/CustomButton';
import StepsContainer from './StepsContainer';
import CurrencyDropdown from '../../components/CurrencyDropdown';

const FirstStep = () => {
  const {
    changeCurrentStep,
    selectedCurrency,
    changeSelectedCurrency,
    interestRate,
  } = useDepositContext();
  const { wallet } = useGlobalContext();
  const { data: approveData } = useContractRead({
    address: tokensAddresses[selectedCurrency || 'USDT'] as `0x${string}`,
    abi: erc20ABI,
    functionName: 'allowance',
    args: [wallet as `0x${string}`, dsfAddresses.DSF as `0x${string}`],
    watch: true,
  });
  const { approve, isSuccess, isLoading } = useApprove(tokensAddresses[selectedCurrency || 'USDT'] as `0x${string}`);
  const { t } = useTranslation('deposit');

  const isApproved = useMemo(() => {
    if (isLoading) return false;
    if (approveData && (+ethers.formatUnits(approveData, 6) !== 0)) {
      return true;
    }
    if (isSuccess) return true;

    return false;
  }, [approveData, isSuccess, isLoading]);

  const buttonContent= useMemo(() => {
    if (!selectedCurrency || !isApproved) {
      return (
        <div className="flex items-center justify-center">
          <WalletIcon className="wallet-white" />
          <span className="ml-4">
            {t('approve')}
          </span>
        </div>
      )
    }

    return t('next', { ns: 'common' });
  } ,[isApproved, selectedCurrency]);

  const buttonClickHandler = useCallback(() => {
    if (isApproved) {
      changeCurrentStep(1);
    } else {
      approve();
    }
  }, [isApproved, changeCurrentStep, approve]);

  return (
    <StepsContainer title={t('currency')}>
      <div className="tablet:flex items-center gap-[20px]">
        <div className="mt-6 tablet:w-1/2">
          <label className="font-medium text-[0.75rem]">
            {t('select_coin')}
          </label>
          <CurrencyDropdown selectedCurrency={selectedCurrency} changeSelectedCurrency={changeSelectedCurrency} />
        </div>
        <div hidden={!selectedCurrency} className="mt-6 font-medium tablet:w-1/2">
          <label className="flex items-center text-sm mb-1">
            {t('estimate_rate', { coin: selectedCurrency })}
            <span>
              <Tooltip content={
                <div className="max-w-xs">
                  {t('estimate_hint')}
                </div>
              } style="light" arrow={false}>
              <QuestionIcon height={16} width={16} className="ml-1" />
            </Tooltip>
            </span>
          </label>
          <input disabled value={`${interestRate}%`} className="text-gray-900 py-[10px] px-4 rounded-xl w-full" />
        </div>
      </div>
      <div className="tablet:flex justify-between items-center gap-4 tablet:w-1/2">
        <div className="mt-6">
        <Tooltip content={
                <div className="max-w-xs">
                  {t('check_erc20')}
                </div>
              } style="light" arrow={false}>
               <Button variant="filled" onClick={buttonClickHandler} disabled={!selectedCurrency}>{buttonContent}</Button>
            </Tooltip>
        </div>
        <div hidden={isApproved} className="font-medium mt-6 text-[14px] leading-[20px]">
          {t('you_will_be_redirected')}
        </div>
      </div>
    </StepsContainer>
  )
}

export default FirstStep;
