import {
  useState,
  useMemo,
  useCallback,
} from 'react';
import { DepositContextData } from '../context/DepositContext';

const useDepositContextValue = (): DepositContextData => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [selectedCurrency, setSelectedCurrency] = useState<StableType | null>(null);
  const [optimized, setOptimized] = useState<boolean>(true);
  const [depositAmount, setDepositAmount] = useState<string>('');
  const [optimizedGasPrice, setOptimizedGasPrice] = useState<number>(0);
  const [gasPrice, setGasPrice] = useState<number>(0);
  const [interestRate, setInterestRate] = useState<number>(0);

  const resetContext = useCallback(() => {
    setCurrentStep(0);
    setSelectedCurrency(null);
    setOptimized(true);
    setDepositAmount('');
  },[
    setCurrentStep, setSelectedCurrency,
    setOptimized, setDepositAmount,
  ]);

  const resetCurrentStepToStep = useCallback((stepIndex: number) => {
    if (stepIndex > currentStep) return;
    if (stepIndex === 0) {
      setCurrentStep(0);
      setOptimized(true);
      setDepositAmount('');
      return;
    }
    if (stepIndex === 1) {
      setCurrentStep(1);
      setOptimized(true);
      return;
    }
    setCurrentStep(stepIndex);
  }, [setCurrentStep, currentStep, setOptimized, setDepositAmount]);

  return useMemo(() => ({
    currentStep,
    changeCurrentStep: setCurrentStep,
    selectedCurrency,
    changeSelectedCurrency: setSelectedCurrency,
    optimized,
    changeOptimized: setOptimized,
    depositAmount,
    changeDepositAmount: setDepositAmount,
    optimizedGasPrice,
    changeOptimizedGasPrice: setOptimizedGasPrice,
    gasPrice,
    changeGasPrice: setGasPrice,
    resetContext,
    interestRate,
    setInterestRate,
    resetCurrentStepToStep,
  }), [
    currentStep, setCurrentStep, selectedCurrency,
    setSelectedCurrency, optimized, setOptimized,
    depositAmount, setDepositAmount,
    optimizedGasPrice, setOptimizedGasPrice,
    gasPrice, setGasPrice,
    resetContext, interestRate, setInterestRate,
    resetCurrentStepToStep,
  ]);
}

export default useDepositContextValue;