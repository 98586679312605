import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'flowbite-react';
import { useContractWrite, usePrepareContractWrite } from 'wagmi';
import dsfAbi from '../../utils/dsf_abi.json';
import dsfAddresses from '../../utils/dsf_addresses.json';
import { useTranslation } from 'react-i18next';

// icons
import { ReactComponent as ETHIcon } from '../../assets/images/currency/ETH.svg';
import { ReactComponent as LinkIcon } from '../../assets/images/common/link.svg';
import { ReactComponent as QuestionIcon } from '../../assets/images/common/question.svg';

// components
import CompletedTransactionCoinContent from './CompletedTransactionCoinContent';
import IncompletedTransactionCoinContent from './IncompletedTransactionCoinContent';
import CompletedTransactionContent from './CompletedTransactionContent';
import IncompletedTransactionContent from './IncompletedTransactionContent';
import Button from '../../components/CustomButton';

interface DesktopTransactionProps {
  timestamp: Date;
  transactionType: TransactionType;
  transactionAction: TransactionActionType;
  transactionToken: StableType;
  transactionAmount: number;
  amountPlaced?: number;
  commission: number;
  isFinished: boolean;
  txHash: string;
  ETHPrice: number;
}

const DesktopTransaction = (props:DesktopTransactionProps) => {
  const {
    timestamp,
    transactionType,
    transactionAction,
    transactionToken,
    transactionAmount,
    amountPlaced,
    commission,
    isFinished,
    txHash,
    ETHPrice,
  } = props;

  const { config } = usePrepareContractWrite({
    address: dsfAddresses.DSF as `0x${string}`,
    abi: dsfAbi,
    functionName: transactionAction === 'deposit' ? 'removePendingDeposit' : 'removePendingWithdrawal',
  });
  const { write: removeTransactions } = useContractWrite(config);
  const { t } = useTranslation('statistics');

  const transactionActionContent = useMemo(() => {
    if (transactionType === 'default') {
      return transactionAction;
    }
    return `${transactionType} ${transactionAction}`
  }, [transactionType, transactionAction]);

  const commissionInUSD = useMemo(
    () => +(commission * ETHPrice).toFixed(2),
    [commission, ETHPrice]
  );

  const cancelTransaction = useCallback(() => {
    removeTransactions?.();
  }, [removeTransactions]);

  return (
    <tr className="bg-gray">
      <td className="py-3 px-4 rounded-l-2xl">{timestamp.toLocaleString()}</td>
      <td className="py-3">
        <span className={`${transactionType} capitalize`}>{t(transactionActionContent)}</span>
      </td>
      <td className="py-3">
        {
          isFinished && amountPlaced
            ? <CompletedTransactionCoinContent
                transactionToken={transactionToken}
                transactionAmount={transactionAmount}
                amountPlaced={amountPlaced}
                transactionAction={transactionAction}
              />
            : <IncompletedTransactionCoinContent
                transactionToken={transactionToken}
                transactionAmount={transactionAmount}
              />
        }
      </td>
      <td className="py-3">
        <div className="flex items-center gap-2">
          <ETHIcon />
          <span>
            {`${commission.toLocaleString('en-US')} `}
            ETH
          </span>
          <span className="text-default">
            $ {(commissionInUSD).toLocaleString('en-US')}
          </span>
        </div>
      </td>
      <td className="py-2 max-w-[160px]">
        {
          isFinished
            ? <CompletedTransactionContent
                transactionAction={transactionAction}
              />
            : <IncompletedTransactionContent
                transactionAction={transactionAction}
                cancelTransaction={cancelTransaction}
                isDisableToCancel={false}
              />
        }
      </td>
      <td className="py-2 button-cell">
        {
          isFinished
          ? null
          : (
            <div className="relative">
              <Tooltip content={
                <div className="w-[200px]">
                  In case of canceling the Smart Deposit, the funds will be returned to your wallet.
                </div>
              } style="light" arrow={false}>
                <QuestionIcon height={16} width={16} className="absolute -right-1 -top-2" />
              </Tooltip>
              <Button onClick={cancelTransaction} variant="outline">Cancel</Button>
            </div>
          )
        }
      </td>
      <td className="py3 px-4 rounded-r-2xl">
        <Link to={`https://etherscan.io/tx/${txHash}`} target="_blank" rel="noreferrer noopener">
          <LinkIcon />
        </Link>
      </td>
    </tr>
  );
};

export default DesktopTransaction;
