// components
import CurrencyIcon from '../../components/CurrencyIcon';
import { ReactComponent as QuestionIcon } from '../../assets/images/common/question.svg';

interface IncompletedTransactionCoinContentProps {
  transactionToken: StableType;
  transactionAmount: number;
  amountPlaced: number;
  transactionAction: TransactionActionType;
}

const CompletedTransactionCoinContent = (props: IncompletedTransactionCoinContentProps) => {
  const {
    transactionAmount,
    amountPlaced,
    transactionToken,
    transactionAction,
  } = props;

  return (
    <div className="flex flex-col gap-[6px] tablet:items-start">
      <div className={`${transactionAction === 'deposit' ? 'flex' : 'hidden'} items-center justify-end text-default`}>
        {/* <span className="mr-3">Deposited</span> */}
        <div className="flex items-center transaction-currency">
          <CurrencyIcon currency={transactionToken} />
          <span className="ml-1 text-sm">
            {transactionAmount.toLocaleString('en-US')}
            {` ${transactionToken}`}
            </span>
        </div>
      </div>
      <div className="flex items-center justify-end text-default">
        {/* <span className={`${transactionAction === 'deposit' ? 'inline' : 'hidden'} mr-3`}>
          Placed
        </span> */}
        
        <div className={`flex items-center transaction-currency ${transactionAction === 'deposit' ? 'hidden' : 'inline'}`}>
          <CurrencyIcon currency={transactionToken} />
          <span className="ml-1 text-sm">
            {amountPlaced.toLocaleString('en-US')}
            {` ${transactionToken}`}
          </span>
          <QuestionIcon className="ml-1" />
        </div>
      </div>
      
    </div>
  )
};

export default CompletedTransactionCoinContent;