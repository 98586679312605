import {
  useEffect,
  useState,
} from 'react';
import { useAccount } from 'wagmi';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';

// components
import Navigation from '../../components/Navigation';
import NotConnected from './header/NotConntected';
import Connected from './header/Connected';

const Header = () => {
  const {
    isConnected,
  } = useAccount();
  const [coloredHeader, setColoredHeader] = useState<boolean>(false);

  useEffect(() => {
    const scrollEvent = () => {
      setColoredHeader(window.scrollY > 50);
    };

    window.addEventListener('scroll', scrollEvent);

    return () => window.removeEventListener('scroll', scrollEvent);
  }, []);

  return (
    <header className={`z-10 sticky top-0 left-0 w-full h-[68px] ${coloredHeader ? 'filled' : ''}`}>
      <div className="flex justify-between h-full container mx-auto">
        <div className="flex items-center tablet:flex-row-reverse">
          <Navigation />
          {/* <Link to="/deposit">  */}
          <Link to="https://dsf.finance">
            <Logo className="ml-3 tablet:ml-0 tablet:mr-6" />
          </Link>
        </div>
        {
          isConnected
            ? <Connected />
            : <NotConnected />
        }
      </div>
    </header>
  )
};

export default Header;
