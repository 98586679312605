import {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import axios from 'axios';
import {
  useAccount,
  useContractRead,
  erc20ABI,
  useNetwork,
  useSwitchNetwork,
} from 'wagmi';
import { useWeb3Modal } from '@web3modal/react';
import { ethers } from 'ethers';
import useGlobalContext from '../hooks/useGlobalContext';
import dsfAddresses from '../utils/dsf_addresses.json';
import tokensAddresses from '../utils/tokens_addresses.json';
import crvRewardsAbi from '../utils/crv_rewards_abi.json';
import cvxTokenAbi from '../utils/cvx_token_abi.json';
import uniswapRouterAbi from '../utils/uniswap_router_abi.json';
import dsfHelperABI from '../utils/dsf_helper_abi.json';
// import additionalRewardsAbi from '../utils/additional_rewards_abi.json';
// import magicAddresses from '../utils/magic_addresses.json';
import magicNumbers from '../utils/magic_numbers.json';
import { Trans, useTranslation } from 'react-i18next';
import { RotatingLines } from 'react-loader-spinner'

// components
import Deposits from './dashboard/Deposits';
import AccruedYield from './dashboard/AccruedYield';
// import AdditionalRewards from './dashboard/AdditionalRewards';
import SavedGas from './dashboard/SavedGas';
// import TotalEarned from './dashboard/TotalEarned';
import FAQ from './dashboard/FAQ';

const DashboardPage = () => {
  const [interestRate, setInterestRate] = useState<number>(0);
  const [currentInterestRate, setCurrentInterestRate] = useState<number>(0);
  const [pendingDeposits, setPendingDeposits] = useState<Deposit[]>([]);
  const [pendingWithdrawals, setPendingWithdrawals] = useState<Withdrawal[]>([]);
  const [gasSavedOnDeposits, setGasSavedOnDeposits] = useState<number>(0);
  const [gasSavedOnWithdrawals, setGasSavedOnWithdrawals] = useState<number>(0);
  // const [cvxCRVAmountByDSF, setCvxCRVAmountByDSF] = useState<bigint>(BigInt(0));
  // const [cvxFXSAmountByDSF, setCvxFXSAmountByDSF] = useState<bigint>(BigInt(0));
  // const [FXSAmountByDSF, setFXSAmountByDSF] = useState<bigint>(BigInt(0));
  const [selectedCurrencyPrice, setSelectedCurrencyPrice] = useState<number>(0);

  // NEW
  const [userDepositsAPI, setUserDepositsAPI] = useState<string>('0.00');
  const [dsfLpBalance, setDsfLpBalance] = useState<string>('0.000000000000000000');
  const [ratioUserAPI, setRatioUserAPI] = useState<string>('0.0000000000000000');
  const [availableToWithdrawAPI, setAvailableToWithdrawAPI] = useState<string>('0.000000');
  const [availableToWithdrawUSDTAPI, setAvailableToWithdrawUSDTAPI] = useState<number>(0);
  const [cvxShareAPI, setCvxShareAPI] = useState<string>('0.000000000000000000');
  const [cvxCostAPI, setCvxCostAPI] = useState<string>('0.000000');
  const [crvShareAPI, setCrvShareAPI] = useState<string>('0.000000000000000000');
  const [crvCostAPI, setCrvCostAPI] = useState<string>('0.000000');
  const [userAnnualYieldRate, setUserAnnualYieldRate] = useState<string>('0.00');
  const [isRequestOk, setIsRequestOk] = useState<boolean>(false);

  const { address } = useAccount();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const { open } = useWeb3Modal()
  const { t } = useTranslation('deposit');

  const fixedAddress = "0x9Af20CCf97b53BC40B1741D44C5AD34f28eE4CE7";

  const {
    managedInDSF,
    userDeposits,
    withdrawableIncome,
  } = useGlobalContext();
  const { data: crvEarnedByDSF } = useContractRead({
    address: dsfAddresses.crvRewards as `0x${string}`,
    abi: crvRewardsAbi,
    functionName: 'earned',
    args: [dsfAddresses.DSFStrategy],
  });
  const { data: DSFcrvBalance } = useContractRead({
    address: tokensAddresses.CRV as `0x${string}`,
    abi: erc20ABI,
    functionName: 'balanceOf',
    args: [dsfAddresses.DSFStrategy as `0x${string}`],
  });
  const { data: DSFcvxBalance } = useContractRead({
    address: tokensAddresses.CVX as `0x${string}`,
    abi: cvxTokenAbi,
    functionName: 'balanceOf',
    args: [dsfAddresses.DSFStrategy as `0x${string}`],
  });
  const { data: cvxTotalCliffs } = useContractRead({
    address: tokensAddresses.CVX as `0x${string}`,
    abi: cvxTokenAbi,
    functionName: 'totalCliffs',
  });
  const { data: cvxTotalSupply } = useContractRead({
    address: tokensAddresses.CVX as `0x${string}`,
    abi: cvxTokenAbi,
    functionName: 'totalSupply',
  });
  const { data: cvxReductionPerCliff } = useContractRead({
    address: tokensAddresses.CVX as `0x${string}`,
    abi: cvxTokenAbi,
    functionName: 'reductionPerCliff',
  });
  
  // const { data: additionalRewardsAmounts }: { data: Array<{ amount: bigint, token: string }> | undefined } = useContractRead({
  //   address: dsfAddresses.additionalRewards as `0x${string}`,
  //   abi: additionalRewardsAbi,
  //   functionName: 'claimableRewards',
  //   args: [magicAddresses.ADDITIONAL_REWARDS as `0x${string}`],
  // });
  // const { data: cvxCRVAmountByDSFInUsd }: { data: Array<bigint> | undefined } = useContractRead({
  //   address: dsfAddresses.uniswapV2Router as `0x${string}`,
  //   abi: uniswapRouterAbi,
  //   functionName: 'getAmountsOut',
  //   args: [cvxCRVAmountByDSF, [tokensAddresses.cvxCRV, tokensAddresses.WETH, tokensAddresses.USDT]],
  // });
  const { data: ratioUser }: { data: bigint | undefined } = useContractRead({
    address: dsfAddresses.DSFHelper as `0x${string}`,
    abi: dsfHelperABI,
    functionName: 'calculateLpRatio',
    args: [address],
    //args: [fixedAddress], 
  });
  // const { data: cvxFXSAmountByDSFInUsd }: { data: Array<bigint> | undefined } = useContractRead({
  //   address: dsfAddresses.uniswapV2Router as `0x${string}`,
  //   abi: uniswapRouterAbi,
  //   functionName: 'getAmountsOut',
  //   args: [cvxFXSAmountByDSF, [tokensAddresses.cvxFXS, tokensAddresses.WETH, tokensAddresses.USDT]],
  // });
  // const { data: FXSAmountByDSFInUsd }: { data: Array<bigint> | undefined } = useContractRead({
  //   address: dsfAddresses.uniswapV2Router as `0x${string}`,
  //   abi: uniswapRouterAbi,
  //   functionName: 'getAmountsOut',
  //   args: [FXSAmountByDSF, [tokensAddresses.FXS, tokensAddresses.WETH, tokensAddresses.USDT]],
  // });

  const remainCliffs = useMemo(() => {
    if (!cvxTotalCliffs || !cvxTotalSupply || !cvxReductionPerCliff) {
      return BigInt(0);
    }
    return (cvxTotalCliffs as bigint) - (cvxTotalSupply as bigint) / (cvxReductionPerCliff as bigint);
  }, [cvxTotalCliffs, cvxTotalSupply, cvxReductionPerCliff]);

  const totalCvxManagedByDSFInTokens = useMemo(
    () => {
      const crvEarned = crvEarnedByDSF as undefined | bigint || BigInt(0);
      const totalCliffs = cvxTotalCliffs as undefined | bigint || BigInt(0);
      const cvxBalance = DSFcvxBalance as undefined | bigint || BigInt(0);
      return (crvEarned * remainCliffs) / (totalCliffs || BigInt(1)) + cvxBalance;
    },
    [
      DSFcvxBalance, cvxTotalCliffs, remainCliffs, crvEarnedByDSF
    ]
  );

  const totalCvxManagedByDSF = useMemo(
    () => +ethers.formatUnits(DSFcrvBalance || 0, 18) +
      +ethers.formatUnits(crvEarnedByDSF as undefined | bigint || 0, 18),
    [crvEarnedByDSF, DSFcrvBalance]
  );

  const totalCrvManagedByDSF = useMemo(
    () => {
      const crvEarned = crvEarnedByDSF as undefined | bigint || BigInt(0);
      const totalCliffs = cvxTotalCliffs as undefined | bigint || BigInt(0);
      const cvxBalance = DSFcvxBalance as undefined | bigint || BigInt(0);
      return +ethers.formatUnits((crvEarned * remainCliffs) / (totalCliffs || BigInt(1)) + cvxBalance, 18);
    },
    [
      DSFcvxBalance, cvxTotalCliffs, remainCliffs, crvEarnedByDSF
    ]
  );

  const cvxShare = useMemo(() => {
    return totalCrvManagedByDSF *
      +ethers.formatUnits(ratioUser || BigInt(0), 18) *
      magicNumbers.DSF_COMMISSION_MULTIPLIER;
  }, [totalCrvManagedByDSF, ratioUser]);

  const crvShare = useMemo(() => {
    return totalCvxManagedByDSF *
      +ethers.formatUnits(ratioUser || BigInt(0), 18) *
      magicNumbers.DSF_COMMISSION_MULTIPLIER;
  }, [totalCvxManagedByDSF, ratioUser]);
  
  // const userProfit = useMemo(() => {
  //   return managedInDSF * dsfLPPrice * selectedCurrencyPrice - Object.values(userDeposits).reduce((acc, curr) => acc + curr, 0);
  // }, [managedInDSF, dsfLPPrice, userDeposits, selectedCurrencyPrice]);
  
  
  const { data: totalAmountCvxByDSFInUsd }: { data: Array<bigint> | undefined } = useContractRead({
    address: dsfAddresses.uniswapV2Router as `0x${string}`,
    abi: uniswapRouterAbi,
    functionName: 'getAmountsOut',
    args: [BigInt(Math.floor(cvxShare *1e18)), [tokensAddresses.CVX, tokensAddresses.WETH, tokensAddresses.USDT]],
    //args: [totalCvxManagedByDSFInTokens, [tokensAddresses.CVX, tokensAddresses.WETH, tokensAddresses.USDT]],
  });

  const { data: totalCrvByDSFInUsd }: { data: Array<bigint> | undefined } = useContractRead({
    address: dsfAddresses.uniswapV2Router as `0x${string}`,
    abi: uniswapRouterAbi,
    functionName: 'getAmountsOut',
    args: [BigInt(Math.floor(crvShare *1e18)),[tokensAddresses.CRV, tokensAddresses.WETH, tokensAddresses.USDT]],
    //args: [
    //  (DSFcvxBalance as null | bigint || BigInt(0)) + (crvEarnedByDSF as null | bigint || BigInt(0)),
    //  [tokensAddresses.CRV, tokensAddresses.WETH, tokensAddresses.USDT]
    //],
  });


    const cvxCost = useMemo(() => {
    if (!totalAmountCvxByDSFInUsd) return 0;

    return +ethers.formatUnits(
      totalAmountCvxByDSFInUsd[totalAmountCvxByDSFInUsd.length - 1] as bigint || 0, 6
    );
  }, [totalAmountCvxByDSFInUsd]);

  const crvCost = useMemo(() => {
    if (!totalCrvByDSFInUsd) return 0;

    return +ethers.formatUnits(
      totalCrvByDSFInUsd[totalCrvByDSFInUsd.length - 1] as bigint || 0, 6
    );
  }, [totalCrvByDSFInUsd]);

  // const userCvxShare = useMemo(() => {
  //   return ((userProfit - withdrawableIncome) * cvxShare) / cvxCost;
  // }, [userProfit, cvxShare, cvxCost, withdrawableIncome]);

  // const userCrvShare = useMemo(() => {
  //   return ((userProfit - withdrawableIncome) * crvShare) / crvCost;
  // }, [userProfit, crvShare, crvCost, withdrawableIncome]);

  // const userShare = useMemo(() => {
  //   return managedInDSF / dsfLpTotalSupply;
  // },[dsfLpTotalSupply, managedInDSF]);

  // const userCvxCrvShare = useMemo(
  //   () => +ethers.formatUnits(cvxCRVAmountByDSF) * userShare * magicNumbers.DSF_COMMISSION_MULTIPLIER,
  //   [cvxCRVAmountByDSF, userShare]
  // );
  //
  // const userCvxFxsShare = useMemo(
  //   () => +ethers.formatUnits(cvxFXSAmountByDSF) * userShare * magicNumbers.DSF_COMMISSION_MULTIPLIER,
  //   [cvxFXSAmountByDSF, userShare]
  // );
  //
  // const userFxsShare = useMemo(
  //   () => +ethers.formatUnits(FXSAmountByDSF) * userShare * magicNumbers.DSF_COMMISSION_MULTIPLIER,
  //   [FXSAmountByDSF, userShare]
  // );
  //
  // const userCvxCrvShareInUsd = useMemo(() => {
  //   if (!cvxCRVAmountByDSFInUsd || !userShare) return 0;
  //
  //   return +ethers.formatUnits(
  //     cvxCRVAmountByDSFInUsd[cvxCRVAmountByDSFInUsd.length - 1] as bigint || 0, 6
  //   ) * userShare * magicNumbers.DSF_COMMISSION_MULTIPLIER;
  // }, [cvxCRVAmountByDSFInUsd, userShare]);
  //
  // const userCvxFxsShareInUsd = useMemo(() => {
  //   if (!cvxFXSAmountByDSFInUsd || !userShare) return 0;
  //
  //   return +ethers.formatUnits(
  //     cvxFXSAmountByDSFInUsd[cvxFXSAmountByDSFInUsd.length - 1] as bigint || 0, 6
  //   ) * userShare * magicNumbers.DSF_COMMISSION_MULTIPLIER;
  // }, [cvxFXSAmountByDSFInUsd, userShare]);
  //
  // const userFxsShareInUsd = useMemo(() => {
  //   if (!FXSAmountByDSFInUsd || !userShare) return 0;
  //
  //   return +ethers.formatUnits(
  //     FXSAmountByDSFInUsd[FXSAmountByDSFInUsd.length - 1] as bigint || 0, 6
  //   ) * userShare * magicNumbers.DSF_COMMISSION_MULTIPLIER;
  // }, [FXSAmountByDSFInUsd, userShare]);

  const getYields = useCallback(async () => {
    const response = await axios.get('https://api2.dsf.finance/yields_llama');

    return response.data.data;
  }, []);

  const getPendingTransactions = useCallback(async () => {
    try {
      const response = await axios.get(`https://api.dsf.finance/deposit/pending/${address}`);

      return response.data;
    } catch (error) {
      console.error('Something went wrong');
    }

    return {
      deposits: [],
      withdrawals: [],
    };
  }, [address]);

  const getGasSaved = useCallback(async () => {
    try {
      const response = await axios.get(`https://api.dsf.finance/deposit/gas-saved/${address}`);

      return response.data;
    } catch (error) {
      console.error('Something went wrong');
    }
    return {
      deposits: 0,
      withdrawals: 0,
    }
  }, [address]);


    // NEW
    const fetchData = useCallback(async () => {
        const fetchWithRetry = async (retries = 5, delay = 1000) => {
            try { 
                //const response = await axios.get(`https://api2.dsf.finance/wallet/${address}`);
                const response = await axios.get(`https://api2.dsf.finance/request/walletinfo?wallet=${address}&apikey=a5d8b4f2-6e3a-49b1-8c5e-3d7b2a4c6f1e`);
                if(response.status === 200){setIsRequestOk(true)}
                console.log(response);
                const data = response.data;
                console.log("API request:", data);
                setUserDepositsAPI(data.user_deposits);
                setDsfLpBalance(data.dsf_lp_balance);
                setRatioUserAPI(data.ratio_user);
                setAvailableToWithdrawAPI(data.available_to_withdraw);
                setAvailableToWithdrawUSDTAPI(data.available_to_withdraw - data.user_deposits);
                setCvxShareAPI(data.cvx_share);
                setCvxCostAPI(data.cvx_cost);
                setCrvShareAPI(data.crv_share);
                setCrvCostAPI(data.crv_cost);
                setUserAnnualYieldRate(data.annual_yield_rate);
            } catch (error) {
                console.error('Something went wrong while fetching API data: ', error);
                if (retries > 0) {
                    console.log(`Retrying... (${retries} retries left)`);
                    setTimeout(() => fetchWithRetry(retries - 1, delay), delay);
                } else {
                    console.error('Failed to fetch data after multiple attempts:', error);
                }
            }
        };
    
        await fetchWithRetry();
    }, [address]);


  const getAnnualIncome = useCallback((yields: Array<{ apy: number }>): number => {
    return +((yields.reduce((a, b) => (a + b.apy), 0) / yields.length) * 0.85).toFixed(2);
  }, []);

  useEffect(() => {
    getYields()
      .then((yields) => {
        setInterestRate(getAnnualIncome(yields));
        setCurrentInterestRate(+(yields.at(-1).apy * 0.85).toFixed(2));
      })
      .catch((error) => {
        console.error('Something went wrong while getting yields: ', error);
      });
  }, [getYields, getAnnualIncome]);

  useEffect(() => {
    getPendingTransactions().then((transactions) => {
      const { deposits, withdrawals } = transactions;

      setPendingDeposits(deposits);
      setPendingWithdrawals(withdrawals);
    });
  }, [getPendingTransactions]);

  useEffect(() => {
    getGasSaved().then((gasSaved) => {
      const { deposits, withdrawals } = gasSaved;

      setGasSavedOnDeposits(deposits);
      setGasSavedOnWithdrawals(withdrawals);
    });
  }, [getGasSaved]);

    // NEW
    useEffect(() => {
      fetchData();
    }, [fetchData]);

  // useEffect(() => {
  //   if (additionalRewardsAmounts) {
  //     const cvxCRVAddress = tokensAddresses.cvxCRV;
  //     const cvxFXSAddress = tokensAddresses.cvxFXS;
  //     const FXSAddress = tokensAddresses.FXS;
  //
  //     additionalRewardsAmounts.forEach((reward) => {
  //       switch (reward.token) {
  //         case cvxCRVAddress:
  //           setCvxCRVAmountByDSF(reward.amount);
  //           break;
  //         case cvxFXSAddress:
  //           setCvxFXSAmountByDSF(reward.amount);
  //           break;
  //         case FXSAddress:
  //           setFXSAmountByDSF(reward.amount);
  //           break;
  //         default:
  //           break;
  //       }
  //     });
  //   }
  // }, [additionalRewardsAmounts]);

  useEffect(() => {
    axios.get('https://api.curve.fi/api/getPools/ethereum/main')
    .then((bigResponse) => {
      return bigResponse.data.data.poolData;
    })
    .then((pools) => {
      return pools.find((pool: any) => pool.id === '40')
    })
    .then((pool) => {
      if (pool) {
        return pool.underlyingCoins
      }
    })
    .then((coins) => {
      if (coins) {
        const coinData = coins.find((coin: any) => coin.symbol === 'MIM');
        if (coinData) {
          setSelectedCurrencyPrice(coinData.usdPrice);
        }
      }
    })
    .catch((error) => {
      console.log('Something went wrong while getting selected currency price: ', error);
    });
  }, []);


  // NEW
  useEffect(() => {
    console.log('----------------------------------------------');
    console.log('managedInDSF', managedInDSF);
    console.log('----------------------------------------------');
    console.log('userDeposits:', userDeposits, userDepositsAPI);
    console.log('currentInterestRate:', currentInterestRate);
    console.log('interestRate:', interestRate);
    console.log('pendingDeposits:', pendingDeposits);
    console.log('pendingWithdrawals:', pendingWithdrawals);
    console.log('withdrawableIncome:', withdrawableIncome, availableToWithdrawUSDTAPI);
    console.log('----------------------------------------------');
    console.log('Ratio User:', ratioUser);
    console.log('Remaining Cliffs:', remainCliffs); // + 
    console.log('Total CVX Managed by DSF in Tokens:', totalCvxManagedByDSFInTokens);
    console.log('----------------------------------------------');
    console.log('Total Amount CVX by DSF in USD:', totalAmountCvxByDSFInUsd);
    console.log('Total Amount CRV by DSF in USD:', totalCrvByDSFInUsd);
    console.log('----------------------------------------------');
    console.log('Total CVX Managed by DSF:', totalCvxManagedByDSF); // + 
    console.log('Total CRV Managed by DSF:', totalCrvManagedByDSF); // + 
    console.log('CVX Share:', cvxShare, cvxShareAPI);
    console.log('CRV Share:', crvShare, crvShareAPI);
    console.log('CVX Cost:', cvxCost, cvxCostAPI);
    console.log('CRV Cost:', crvCost, crvCostAPI);
    //console.log('cvxShareMultiplied:', cvxShareMultiplied);
    console.log('----------------------------------------------');
    console.log('CVX Share BigInt:', BigInt(Math.floor(cvxShare *1e18)));
    console.log('CRV Share BigInt:', BigInt(Math.floor(crvShare *1e18)));
    console.log('----------------------------------------------');
  }, [
    ratioUser,
    remainCliffs,
    totalCvxManagedByDSFInTokens,
    totalAmountCvxByDSFInUsd,
    totalCrvByDSFInUsd,
    totalCvxManagedByDSF,
    totalCrvManagedByDSF,
    cvxShare,
    crvShare,
    cvxCost,
    crvCost,
    cvxShareAPI,
    crvShareAPI,
    cvxCostAPI,
    crvCostAPI,
    userDeposits,
    currentInterestRate,
    interestRate,
    pendingDeposits,
    pendingWithdrawals,
    withdrawableIncome,
    availableToWithdrawAPI,
    availableToWithdrawUSDTAPI,
    userDepositsAPI,
    managedInDSF
  ]);

  function renderConnectWallet() {
    if(!address && window.innerWidth >800) {
      return(
        <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm z-50 flex items-center justify-center">
        <div className="bg-white w-[670px] h-[400px] rounded-3xl shadow-lg flex flex-col items-center justify-center px-[110px]">
        <div className="text-[#3956FE] text-[22px] font-bold text-center">
        {t('please_connect_wallet')}
        </div>
        <button className='flex items-center gap-2 bg-[#3956FE] text-white px-4 py-2 rounded-xl mt-[50px] px-[30px] active:opacity-75' onClick={open}>
          <img src='/metamask.png' alt="wallet" />
          {t('connect_wallet')}
          </button>
          <div className="text-[#979797] text-[16px] font-bold text-center mt-[50px]">
        {t('connect_wallet_description')}
        </div>
        </div>
      </div>
      )
    } else if(!address && window.innerWidth <= 800) {
      return(
        <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm z-50 flex items-center justify-center">
        <div className="bg-white w-[95%] h-[80vh] rounded-3xl shadow-lg flex flex-col items-center justify-center px-[50px]">
        <div className="text-[#3956FE] text-[22px] font-bold text-center">
        {t('please_connect_wallet')}
        </div>
        <button className='flex items-center gap-2 bg-[#3956FE] text-white px-4 py-2 rounded-xl mt-[50px] px-[30px] active:opacity-75' onClick={open}>
          <img src='/metamask.png' alt="wallet" />
          {t('connect_wallet')}
          </button>
          <div className="text-[#979797] text-[16px] font-bold text-center mt-[50px]">
        {t('connect_wallet_description')}
        </div>
        </div>
      </div>
      )
    }
  }


function renderLoader() {
  if(!isRequestOk && window.innerWidth > 800){
    return(
      <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm z-50 flex items-center justify-center">
      <div className="bg-white w-[670px] h-[150px] rounded-3xl shadow-lg flex flex-row items-center justify-center px-[60px]">
      <RotatingLines
        strokeColor="#3956FE"
        strokeWidth="5"
        animationDuration="0.75"
        width="70"
        visible={true}
      />
      <div className="text-[#3956FE] text-[22px] font-bold text-center ml-[20px]">
      {t('loader_content')}
      </div>
      </div>
    </div>
    )
  } else if(!isRequestOk && window.innerWidth <= 800){
    return(
      <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm z-50 flex items-center justify-center">
      <div className="bg-white w-[95%] h-[150px] rounded-3xl shadow-lg flex flex-row items-center justify-center px-[30px]">
      <RotatingLines
        strokeColor="#3956FE"
        strokeWidth="5"
        animationDuration="0.75"
        width="70"
        visible={true}
      />
      <div className="text-[#3956FE] text-[22px] font-bold text-center ml-[20px]">
      {t('loader_content')}
      </div>
      </div>
    </div>
    )
  }
}
  return (
    <div className="flex flex-col tablet:flex-row gap-8 tablet:gap-10 text-[14px] max-h-[calc(100vh-68px-2.5rem)] overflow-y-scroll">
      {renderConnectWallet()}
      {renderLoader()}
      <section className="tablet:w-3/5 tablet:sticky top-0 left-0">
        <Deposits
          depositAmount={userDepositsAPI}
          currentInterestRate={currentInterestRate}
          interestRate={interestRate}
          pendingDeposits={pendingDeposits}
          pendingWithdrawals={pendingWithdrawals}
          availableToWithdraw={availableToWithdrawUSDTAPI} 
          userAnnualYieldRate={userAnnualYieldRate}
        />
      </section>
      <div className="flex flex-col gap-10 tablet:w-full">
        {/*<section>*/}
        {/*  <TotalEarned*/}
        {/*    userMainProfit={Math.max(userProfit, withdrawableIncome)}*/}
        {/*    cvxCRVAmount={userCvxCrvShareInUsd}*/}
        {/*    cvxFXSAmount={userCvxFxsShareInUsd}*/}
        {/*    FXSAmount={userFxsShareInUsd}*/}
        {/*  />*/}
        {/*</section>*/}
        <section>
          <AccruedYield
            cvxAmount={parseFloat(cvxShareAPI)}
            crvAmount={parseFloat(crvShareAPI)}
            cvxAmountInUsd={parseFloat(cvxCostAPI)}
            crvAmountInUsd={parseFloat(crvCostAPI)}
            availableToWithdraw={availableToWithdrawUSDTAPI}
            loading={managedInDSF > 0 && !userDeposits}
          />
        </section>
        {/*<section>*/}
        {/*  <AdditionalRewards*/}
        {/*    cvxCRVAmount={userCvxCrvShare}*/}
        {/*    cvxFXSAmount={userCvxFxsShare}*/}
        {/*    FXSAmount={userFxsShare}*/}
        {/*    cvxCRVAmountInUsd={userCvxCrvShareInUsd}*/}
        {/*    cvxFXSAmountInUsd={userCvxFxsShareInUsd}*/}
        {/*    FXSAmountInUsd={userFxsShareInUsd}*/}
        {/*  />*/}
        {/*</section>*/}
        <section>
          <SavedGas
            deposits={gasSavedOnDeposits}
            withdrawals={gasSavedOnWithdrawals}
          />
        </section>
        <section>
          <FAQ />
        </section>
      </div>
    </div>
  );
};

export default DashboardPage;
