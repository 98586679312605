import {
  useState,
  useCallback,
  useMemo,
} from 'react';
import { Tooltip } from 'flowbite-react';
import dropdownIcon from '../../assets/images/common/dropdown.png';
import { ReactComponent as QuestionMark } from '../../assets/images/common/question.svg';
import { useTranslation, Trans } from 'react-i18next';

// components
import Card from '../../components/Card';
import CurrencyIcon from '../../components/CurrencyIcon';
import Timer from '../../components/Timer';

interface AccruedYieldProps {
  cvxAmount: number;
  crvAmount: number;
  cvxAmountInUsd: number;
  crvAmountInUsd: number;
  availableToWithdraw: number;
  loading: boolean;
}

const AccruedYield = (props: AccruedYieldProps) => {
  const {
    cvxAmount,
    crvAmount,
    cvxAmountInUsd,
    crvAmountInUsd,
    availableToWithdraw,
    loading,
  } = props;
  const [opened, setOpened] = useState<boolean>(false);
  const { t } = useTranslation('dashboard');

  const triggerDropdown = useCallback(() => {
    setOpened(!opened);
  }, [opened]);

  const dateTillNextCompound = useMemo(() => {
    const today = new Date();
    today.setMonth(today.getMonth() + 1);
    today.setDate(1);
    today.setHours(8, 0, 0, 0);
    const dayOfTheWeek = today.getDay();
    if (dayOfTheWeek < 6) {
      today.setDate(today.getDate() + (6 - dayOfTheWeek));
    } else {
      today.setDate(today.getDate() + (13 - dayOfTheWeek));
    }

    return today;
  }, []);

  const totalSum = useMemo(() => {
    const sum = Math.max(crvAmountInUsd, 0) + Math.max(cvxAmountInUsd, 0) + availableToWithdraw;
    if (sum > 0) return sum;
    return 0;
  }, [crvAmountInUsd, cvxAmountInUsd, availableToWithdraw])

  return (
    <Card>
      <div className="flex items-center cursor-pointer" onClick={triggerDropdown}>
        <div className="tablet:flex items-center">
          <h2 className="text-gray-900 text-[24px] leading-8 font-bold">
            {t('total_earned')}
          </h2>
          {/*<div className="tablet:ml-2">*/}
          {/*  <Timer endTime={dateTillNextCompound} />*/}
          {/*</div>*/}
        </div>
        <div className="ml-auto">
          <img src={dropdownIcon} alt="" className={opened ? '' : 'rotate-180'} />
        </div>
      </div>
      <div className={`${opened ? 'flex' : 'hidden'} flex-col gap-4 mt-4 font-medium`}>
        <div className="flex items-center justify-between bg-gray p-4 rounded-2xl">
          <div className="flex items-center gap-2">
            <CurrencyIcon currency="CRV" />
            CRV
          </div>
          <span className="w-1/2">
            {loading ? 0 : (crvAmount > 0 ? crvAmount : 0).toFixed(4)} {t('tokens')}
          </span>
          <span className="text-gray-900 font-semibold">
            ${loading ? 0 : (crvAmountInUsd > 0 ? crvAmountInUsd : 0).toFixed(2)}
          </span>
        </div>
        <div className="flex items-center justify-between bg-gray p-4 rounded-2xl">
          <div className="flex items-center gap-2">
            <CurrencyIcon currency="CVX" />
            CVX
          </div>
          <span className="w-1/2">
            {loading ? 0 : (cvxAmount > 0 ? cvxAmount : 0).toFixed(4)} {t('tokens')}
          </span>
          <span className="text-gray-900 font-semibold">
            ${loading ? 0 : (cvxAmountInUsd > 0 ? cvxAmountInUsd : 0).toFixed(2)}
          </span>
        </div>
        <div className="flex items-center justify-between bg-gray p-4 rounded-2xl w-full">
          <div className="flex items-center gap-2">
            <CurrencyIcon currency="USDT" />
            USDT
          </div>
          <div className="flex items-center gap-1 w-1/2">
              <Trans t={t}>
                {/*Available <span className="hidden tablet:inline">to withdraw</span>*/}
                available_<span className="hidden tablet:inline">to</span>_withdraw
              </Trans>
              {/*Available <span className="hidden tablet:inline">to withdraw</span>*/}
            <span>
              <Tooltip
                content={t('available_hint')}
                style="light"
                arrow={false}
                className="max-w-[300px]"
              >
              <QuestionMark />
            </Tooltip>
          </span>
          </div>
          <span className="text-gray-900 font-semibold">
            ${loading ? 0 : (availableToWithdraw > 0 ? availableToWithdraw : 0).toFixed(2)}
          </span>
        </div>
      </div>
      <div className="flex items-center justify-between bg-gray-100 px-4 py-3 font-medium rounded-2xl mt-4">
        <div className="flex items-center">
          {t('total_earned_total')}, USD
          <span>
              <Tooltip
                content={t('total_earned_hint')}
                style="light"
                arrow={false}
                className="max-w-[300px]"
              >
              <QuestionMark className="ml-2" />
            </Tooltip>
            </span>
        </div>
        <span className="text-gray-900 font-semibold">
          ${loading ? 0 : totalSum.toFixed(2)}
        </span>
      </div>
    </Card>
  );
};

export default AccruedYield;
