import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface CompletedTransactionContentProps {
  transactionAction: TransactionActionType;
}

const CompletedTransactionContent = (props: CompletedTransactionContentProps) => {
  const {
    transactionAction,
  } = props;
  const { t } = useTranslation('statistics');

  const contentText = useMemo(() => {
    switch (transactionAction) {
      case 'deposit':
        return t('funds_placed_status');
      case 'withdrawal':
        return t('funds_withdrawn_status');
      default:
        return 'Completed';
    }
  }, [transactionAction]);

  return (
    <div className="bg-gray-100 tablet:bg-transparent rounded-2xl p-2 text-gray-900 mt-2 text-sm tablet:text-[14px]">
      {contentText}
    </div>
  );
};

export default CompletedTransactionContent;
