import {
  useState,
  useMemo,
  useCallback,
} from 'react';
import { GlobalContextData } from '../context/GlobalContext';

const useGlobalContextValue = (): GlobalContextData => {
  const [wallet, setWallet] = useState<string>('');
  const [ETHPrice, setETHPrice] = useState<number>(0);
  const [gasPrice, setGasPrice] = useState<number>(0);
  const [managedInDSF, setManagedInDSF] = useState<number>(0);
  const [dsfLPPrice, setDSFLPPrice] = useState<number>(0);
  const [dsfLpTotalSupply, setDSFLPTotalSupply] = useState<number>(0);
  const [interestRate, setInterestRate] = useState<number>(0);
  const [currentInterestRate, setCurrentInterestRate] = useState<number>(0);
  const [withdrawableIncome, setWithdrawableIncome] = useState<number>(0);
  const [dsfLpBalance, setDsfLpBalance] = useState<bigint>(BigInt(0));
  const [balance, setBalance] = useState<BalanceType>({
    DAI: 0,
    USDC: 0,
    USDT: 0,
  });
  const [userDeposits, setUserDeposits] = useState<UserDeposits>({
    beforeCompound: 0,
    afterCompound: 0,
  });

  const changeBalance = useCallback((currency: StableType, amount: number) => {
    if (amount < 0) {
      throw new Error('Amount in balance cannot be negative');
    }
    const balanceCopy = { ...balance };
    balanceCopy[currency] = amount;
    setBalance(balanceCopy);
  },[balance, setBalance]);

  const setNewETHPrice = useCallback((newETHPrice: number) => {
    if (newETHPrice < 0) {
      throw new Error('ETH price cannot be negative');
    }

    setETHPrice(newETHPrice);
  },[setETHPrice]);

  return useMemo(() => ({
    wallet,
    changeWallet: setWallet,
    balance,
    changeBalance,
    ETHPrice,
    setETHPrice: setNewETHPrice,
    managedInDSF,
    changeManagedInDSF: setManagedInDSF,
    gasPrice,
    changeGasPrice: setGasPrice,
    dsfLPPrice,
    changeDSFLPPrice: setDSFLPPrice,
    dsfLpTotalSupply,
    changeDSFLPTotalSupply: setDSFLPTotalSupply,
    userDeposits,
    changeUserDeposits: setUserDeposits,
    interestRate,
    changeInterestRate: setInterestRate,
    withdrawableIncome,
    changeWithdrawableIncome: setWithdrawableIncome,
    changeDsfLpBalance: setDsfLpBalance,
    dsfLpBalance,
    currentInterestRate,
    changeCurrentInterestRate: setCurrentInterestRate,
  }), [
    wallet, setWallet,
    changeBalance, setNewETHPrice,
    ETHPrice, balance,
    managedInDSF, setManagedInDSF,
    gasPrice, setGasPrice,
    dsfLPPrice, setDSFLPPrice,
    dsfLpTotalSupply, setDSFLPTotalSupply,
    userDeposits, setUserDeposits,
    interestRate, setInterestRate,
    withdrawableIncome, setWithdrawableIncome,
    dsfLpBalance, setDsfLpBalance,
    currentInterestRate, setCurrentInterestRate,
  ]);
}

export default useGlobalContextValue;