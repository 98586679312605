import React from 'react';

// icons
import MixedToken from './MixedToken';
import { ReactComponent as USDT } from '../assets/images/currency/USDT.svg';
import { ReactComponent as USDC } from '../assets/images/currency/USDC.svg';
import { ReactComponent as DAI } from '../assets/images/currency/DAI.svg';
import { ReactComponent as CRV } from '../assets/images/currency/CRV.svg';
import { ReactComponent as CVX } from '../assets/images/currency/CVX.svg';
import { ReactComponent as cvxCRV } from '../assets/images/currency/cvxCRV.svg';
import { ReactComponent as cvxFXS } from '../assets/images/currency/cvxFXS.svg';
import { ReactComponent as FXS } from '../assets/images/currency/FXS.svg';
import { ReactComponent as ETH } from '../assets/images/currency/ETH.svg';

const CurrencyIcon = ({ currency }: { currency: StableType | YieldType | RewardsType | BlockChainType | 'MIXED' }) => {
    const currencyIcons = {
        USDT: USDT,
        USDC: USDC,
        DAI: DAI,
        CRV: CRV,
        CVX: CVX,
        cvxCRV: cvxCRV,
        cvxFXS: cvxFXS,
        FXS: FXS,
        ETH: ETH,
        MIXED: MixedToken,
    };
    const Icon = currencyIcons[currency];
    return React.createElement(Icon, null);
};

export default CurrencyIcon;
