import { Outlet } from 'react-router-dom';
import { GlobalContext } from '../context/GlobalContext';
import useGlobalContextValue from '../hooks/useGlobalContextValue';
import useDepositContextValue from '../hooks/useDepositContextValue';
import { DepositContext } from '../context/DepositContext';
import useWithdrawalContextValue from '../hooks/useWithdrawalContextValue';
import { WithdrawalContext } from '../context/WithdrawalContext';

// styles
import './styles/header.css';

// components
import Header from './components/Header';
import DataContainer from './components/DataContainer';

export default function Layout() {
  const globalContextValue = useGlobalContextValue();
  const depositContextValue = useDepositContextValue();
  const withdrawalContextValue = useWithdrawalContextValue();

  return (
    <GlobalContext.Provider value={globalContextValue}>
      <DepositContext.Provider value={depositContextValue}>
        <WithdrawalContext.Provider value={withdrawalContextValue}>
          <DataContainer>
            <Header />
            <main className="container mx-auto mt-10">
              <Outlet />
            </main>
          </DataContainer>
        </WithdrawalContext.Provider>
      </DepositContext.Provider>
    </GlobalContext.Provider>
  );
}