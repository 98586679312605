import {
  useMemo,
  useCallback,
} from 'react';
import { Link } from 'react-router-dom';
import {
  usePrepareContractWrite,
  useContractWrite,
} from 'wagmi';
import dsfAddresses from '../../utils/dsf_addresses.json';
import dsfAbi from '../../utils/dsf_abi.json';
import { useTranslation } from 'react-i18next';

// icons
import { ReactComponent as LinkIcon } from '../../assets/images/common/link.svg';
import { ReactComponent as ETHIcon } from '../../assets/images/currency/ETH.svg';

// components
import Card from '../../components/Card';
import CompletedTransactionContent from './CompletedTransactionContent';
import IncompletedTransactionContent from './IncompletedTransactionContent';
import CompletedTransactionCoinContent from './CompletedTransactionCoinContent';
import IncompletedTransactionCoinContent from './IncompletedTransactionCoinContent';

interface TransactionProps {
  timestamp: Date;
  transactionType: TransactionType;
  transactionAction: TransactionActionType;
  transactionToken: StableType;
  transactionAmount: number;
  amountPlaced?: number;
  commission: number;
  isFinished: boolean;
  txHash: string;
  ETHPrice: number;
}

const Transaction = (props: TransactionProps) => {
  const {
    timestamp,
    transactionType,
    transactionAction,
    transactionToken,
    transactionAmount,
    amountPlaced,
    commission,
    isFinished,
    txHash,
    ETHPrice,
  } = props;
  const { config } = usePrepareContractWrite({
    address: dsfAddresses.DSF as `0x${string}`,
    abi: dsfAbi,
    functionName: transactionAction === 'deposit' ? 'removePendingDeposit' : 'removePendingWithdrawal',
  });
  const { write: removeTransactions } = useContractWrite(config);
  const { t } = useTranslation('statistics');

  const transactionActionContent = useMemo(() => {
    if (transactionType === 'default') {
      return transactionAction;
    }
    return `${transactionType} ${transactionAction}`
  }, [transactionType, transactionAction]);

  const commissionInUSD = useMemo(
    () => +(commission * ETHPrice).toFixed(2),
    [commission, ETHPrice]
  );

  const cancelTransaction = useCallback(() => {
    removeTransactions?.();
  }, [removeTransactions]);

  return (
    <Card>
      <div className="mx-[-12px] my-[-21px]">
        <div className="flex items-center justify-between bg-gray rounded-2xl p-2 text-gray-900">
          <div>
            {timestamp.toLocaleString()}
          </div>
          <div className="flex items-center gap-3">
            <span className={`${transactionType} capitalize`}>{t(transactionActionContent)}</span>
            <Link to={`https://etherscan.io/tx/${txHash}`}>
              <LinkIcon />
            </Link>
          </div>
        </div>
        <div className="flex justify-between bg-gray rounded-2xl p-2 text-gray-900 mt-2">
        <span className="text-default">
          {t('coin')}:
        </span>
          {
            isFinished && amountPlaced
              ? <CompletedTransactionCoinContent
                  transactionAmount={transactionAmount}
                  amountPlaced={amountPlaced}
                  transactionToken={transactionToken}
                  transactionAction={transactionAction}
                />
              : <IncompletedTransactionCoinContent
                  transactionAmount={transactionAmount}
                  transactionToken={transactionToken}
                />
          }
        </div>
        <div className="flex justify-between bg-gray rounded-2xl p-2 text-gray-900 mt-2">
        <span className="text-default">
          {t('Commissions_spent')}:
        </span>
          <div className="flex items-center gap-2">
            <ETHIcon />
            <span>
            {`${commission.toLocaleString('en-US')} `}
              ETH
          </span>
            <span className="text-default">
            $ {(commissionInUSD).toLocaleString('en-US')}
          </span>
          </div>
        </div>
        {
          isFinished
            ? <CompletedTransactionContent transactionAction={transactionAction} />
            : <IncompletedTransactionContent
              transactionAction={transactionAction}
              cancelTransaction={cancelTransaction}
              isDisableToCancel={false}
            />
        }
      </div>
    </Card>
  )
};

export default Transaction;
