import useWithdrawalContext from '../../../hooks/useWithdrawalContext';
import { useTranslation } from 'react-i18next';
import { ReactComponent as QuestionMark } from '../../../assets/images/common/question.svg';
// components
import Card from '../../../components/Card';
import { Tooltip } from 'flowbite-react';

const SecondStep = () => {
  const {
    changeCurrentStep,
    changeOptimized,
    optimizedGasPrice,
    gasPrice,
  } = useWithdrawalContext();
  const { t, i18n: { language } } = useTranslation('withdraw');

  const chooseNonOptimized = () => {
    changeOptimized(false);
    changeCurrentStep(2);
  };

  const chooseOptimized = () => {
    changeOptimized(true);
    changeCurrentStep(2);
  };

  return (
    <div>
      <h2 className="font-bold text-gray-900 text-[1.5rem]">
        {t('fees_optimization')}
      </h2>
      <div className="flex flex-col gap-y-4 tablet:flex-row items-stretch justify-between mt-4">
        <div className="w-full tablet:w-[360px]">
          <Card type="default">
            <h3 className="font-semibold text-gray-900">
              {t('withdraw_fastest')}
            </h3>
            <div className="bg-gray px-2 py-1 rounded-xl mt-[10px]">
              {t('estimated_fee', { ns: 'deposit' })}:
              <span className="text-gray-900 ml-[10px] text-[12px] font-medium">
              ${gasPrice.toFixed(2)}
            </span>
            </div>
            <div className="flex items-center min-h-[75px] mt-2">
              {t('withdraw_fastest_hint')}
            </div>
            <div className="flex justify-between items-center mt-[18px]">
              <button
                className={`
                  border-solid border-blue border py-2 px-7 rounded-full tablet:w-max font-semibold bg-blue text-white
                  hover:bg-blue-100 hover:text-white
                `}
                onClick={chooseNonOptimized}
              >
                {t('choose', { ns: 'deposit' })}
              </button>
              {
                language === 'en' && (
                  <div className="text-blue-100 bg-[rgba(57,_86,_254,_0.12)] rounded-full py-2 px-3">
                    Withdrawal
                  </div>
                )
              }
            </div>
          </Card>
        </div>
        <div className="w-full tablet:w-[360px]">
          <Card>
            <div className='flex flex-row' >
            <h3 className="font-semibold text-gray-900">
              {t('withdraw_balanced')}
            </h3>
            <div className='ml-5 cursor-pointer' >
            <Tooltip
            content={t('withdraw_in_3_stablecoins')} 
            style="light"
            arrow={false}
            className="max-w-[300px]"
            >
              {/* <QuestionMark className="" /> */}
              <div className='w-5 h-5 border-2 border-red-700 rounded-xl flex justify-center align-middle'>
              <h2 className='mt-[-4px] text-red-700' >!</h2>
              </div>
            </Tooltip>
            </div>
            
            </div>
            <div className="bg-gray px-2 py-1 rounded-xl mt-[10px]">
              {t('estimated_fee', { ns: 'deposit' })}:
              <span className="text-gray-900 ml-[10px] text-[12px] font-medium">
              ${optimizedGasPrice.toFixed(2)}
            </span>
            </div>
            <div className="flex items-center min-h-[75px] mt-2">
              {t('withdraw_balanced_hint')}
            </div>
            <div className="flex justify-between items-center mt-[18px]">
              <button
                className={`
                  border-solid border-blue border py-2 px-7 rounded-full tablet:w-max font-semibold bg-blue text-white
                  hover:bg-blue-100 hover:text-white
                `}
                onClick={chooseOptimized}
              >
                {t('choose', { ns: 'deposit' })}
              </button>
              {
                language === 'en' && (
                  <div className="text-green-300 bg-[rgba(85,_221,_131,_0.12)] rounded-full py-2 px-2.5">
                    Smart Withdraw
                  </div>
                )
              }
            </div>
          </Card>
        </div>
        <div className="w-full tablet:w-[360px]">
          <Card>
            <div className="flex flex-col justify-between min-h-[219px]">
              <div className="flex items-center">
                <h3 className="font-semibold text-gray-400">
                  {t('withdraw_premium')}
                </h3>
                <span className="text-blue-100 ml-4">
                  {t('soon', { ns: 'common' })}
                </span>
              </div>
              <div>
                {t('withdraw_premium_hint')}
              </div>
              <div className="flex justify-end">
                {
                  language === 'en' && (
                    <div className="text-[#E239FE] bg-[rgba(226,_57,_254,_0.12)] rounded-full py-2 px-3">
                      Premium Withdraw
                    </div>
                  )
                }
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  )
};

export default SecondStep;
