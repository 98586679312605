import { useWeb3Modal } from '@web3modal/react';
import { useTranslation } from 'react-i18next';

// components
import Button from '../../../components/CustomButton';

const NotConnected = () => {
  const {
    open,
  } = useWeb3Modal();
  const { t } = useTranslation('header');

  return (
    <>
      <div className="flex items-center tablet:hidden button__container-mobile">
        <Button variant="outline" onClick={() => open()}>
          {t('wallet')}
        </Button>
      </div>
      <div className="hidden tablet:flex items-center button__container">
        <Button variant="outline" onClick={() => open()}>
          {t('connect_wallet')}
        </Button>
      </div>
    </>
  )
};

export default NotConnected;